*, *:before, *:after {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;

    background: #fff;
    color: #000;
    font-family: sans-serif;
}

body {
    width: 100%;
    max-width: 48rem;
    margin: 0 auto;

    a {
        color: inherit;

        &:hover, &:focus {
            color: inherit;
            text-decoration: none;
        }
    }
}

header {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;

    margin: 1rem 1rem;
    padding: 1rem 0 0.25rem 0;

    img {
        width: 100px;
        height: auto;

        margin: 0 1rem 0 0;
    }

    h1 {
        margin: 0 0 0.25rem 0;
        font-size: 1.75rem;
        font-weight: bold;
    }

    h2 {
        color: #777;
        margin: 0;
        font-size: 1.25rem;
        font-weight: normal;
    }
}

.content {
    margin: 1rem 1rem;
    padding: 1rem 0;

    a {
        color: #A82331;
    }

    h1, h2 {
        font-size: 1.25rem;
        font-weight: bold;

        margin: 0 0 0.25rem 0;
        padding: 0 0 0.125rem 0;
        border-bottom: 1px solid #aaa;
    }
}

footer {
    margin: 1rem 1rem;
    padding: 1rem 0;

    color: #aaa;
    font-size: 0.8rem;
    text-align: center;
}
